import React from 'react'
import Buy from '../Buy/Buy'
import { Link } from '@gatsbyjs/reach-router'

import css from './Main.module.scss'

export default function Main() {
    return (
        <>
            <div className={css.main}>
                <div className={css.menu}>
                    <Link to="/try" className={css.left} style={{ background: 'url(/bg_bw.webp)' }}>
                        <div>Загрузите фотографию и наш алгоритм предоставит на выбор варианты выкладки.</div>
                        <div>Попробовать</div>
                    </Link>
                    <Link to="/create" style={{ background: 'url(/bg_se.webp)' }}>
                        <div>Если у вас уже есть наш набор, получите инструкцию и начинайте выкладывать.</div>
                        <div>Получить инструкцию</div>
                    </Link>
                </div>
            </div>
            <Buy />
        </>
    )
}
