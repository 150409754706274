import React, { useEffect } from "react";
import { useState } from "react";
import css from "./Create.module.scss";
import jExec from "../../utils/jExec";
import Ask4Code from "./Ask4Code";
import UploadPhoto from "../UploadPhoto/UploadPhoto";
import Position from "../Position/Position";
import Submit from "./Submit";
import Schemes from "./Schemes";


const SetSelector = (props) => {
	const [change, setChange] = useState(false);

	const [setID, changeID] = useState(props.set_id || 0);

	const changeSetID = (id) => {
		changeID(id);
		props.onChangeSetID(id);
		console.log(id);
	}

	console.log (props) ;

	if (props.set_id) {
		let selected = false;
		for (let x of props.list) if (props.set_id == x.id) { selected = x; break; }
		if (selected) {
			return <div className={css.selected}>
				<div className={css.text}>
					<div>Ваш набор &mdash;</div>
					<div>{selected.size} см</div>
					<div>{selected.palette}</div>
				</div>
				<button onClick={()=>changeSetID(0)}>Изменить</button>
			</div>
		}
	}

	return <div className={css.selector}>
		<div>Выберите ваш набор</div>
		<div className={css.list}>
			{props.list.map((v, i) =>
				<div style={{background:'url(/bg_bw.webp)'}} onClick={() => changeSetID(v.id)} key={i}>{v.size} см<br />{v.palette}</div>
			)}
		</div>
	</div>

}

export default function Create(props) {
	const [data, setData] = useState(process.env.NODE_ENV === "!development" ? { code: "1111-1111" } : false);
	const [hash, setHash] = useState(process.env.NODE_ENV === "!development" ? "ec3a5fd1f95ae183966f408aa06b80db" : false);
	const [result, setResult] = useState(process.env.NODE_ENV === "!development" ? "752dc3f2ff021a08a1d5f772d0bb6b85" : false);

	const onSuccess = (data) => {
		setData(data);
	};

	const onSubmitted = (schemes) => {
		let nd = { ...data };
		data.schemes = schemes;
		setData(data);
		setResult(false);
		setHash(false);
	};

	const checkInProgress = () => {

	}

	useEffect(() => {
		console.log('DATA', data.code);
		if (data.schemes) {
			let hasProgress = false;
			for (let i of data.schemes) {
				if (i.status == 0) hasProgress = true;
			}
			if (hasProgress) setTimeout(() => load(data.code), 5000);
		}
	}, [data])

	const load = async (code) => {
		let loaded = await jExec("test/code", { code });
		if (loaded.result) {
			loaded.code = props.code;
			setData(loaded);
			if (props.hash) setHash(props.hash)
		}
	};

	useEffect(() => {
		if (props.code) {
			load(props.code);
		}
	}, []);

	const onChangeSetID = async (set_id) => {
		let done = await jExec('test/set_id', { code: data.code, set_id })
		let d = { ...data };
		d.set_id = set_id;
		console.log (d) ;
		setData(d);
	}

	console.log(data, hash, result);

	if (!data) return <Ask4Code onSuccess={onSuccess} />;
	if (data && !hash && !result) {
		return (
			<>
				<SetSelector {...data} onChangeSetID={onChangeSetID} />
				{data.set_id > 0 ? <>
					<UploadPhoto onUpload={setHash} />
					{data.schemes && data.schemes.length ? <Schemes {...data} onSelect={(hash) => setResult(hash)} /> : null}
				</> : null}
			</>
		);
	}
	if (data && hash && !result) return <Position hash={hash} {...data} onSelect={setResult} onCancel={() => setHash(false)} />;
	if (data && result) return <Submit hash={hash} {...data} result={result} onSubmitted={onSubmitted} onChange={(hash) => setResult(hash)} />;
	return null;
}
