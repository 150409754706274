import { useState, useEffect, useCallback, useRef } from "react";

import ImageAreaSelector from "../../shared/ImageAreaSelector";
import DiskImage, { getDiskFilename } from "../../shared/DiskImage";

import getNumberName from '../../utils/getNumberName'


import { CircleLoader } from "react-spinners";

import css from "./Position.module.scss";

import jExec, { jsonExec } from "../../utils/jExec";
import { Link } from "@gatsbyjs/reach-router";

const sizes = {
	5: { title: "40x40", aspect: 1 },
	7: { title: "30x30", aspect: 1 },
	//6: { title: "20x30", aspect: 1.5 },
};

const palettes = {
	6: { title: "Чёрнобелый (9 цветов)" },
	7: { title: "Сепия (14 цветов)" },
};

export default function Position(props) {
	const [area, setArea] = useState(false);
	const [settings, setSettings] = useState(false);

	const [imgWidth, setImgWidth] = useState(0);
	const [imgHeight, setImgHeight] = useState(0);

	const [results, setResults] = useState([]);

	const [examples, setExamples] = useState([]);

	const [aspect, setAspect] = useState(1);
	const [ready, setReady] = useState(false);

	const [working, setWorking] = useState(false);

	const [size, setSize] = useState(props.size || 5);
	const [palette, setPalette] = useState(props.palette || 6);

	const [waiting, setWaiting] = useState(0) ;
	const waitRef = useRef(0) ;

	const [error, setError] = useState(false) ;

	const onChange = (opts) => {
		setArea(opts.area);
		setSettings(opts.settings);
	};

	useEffect(() => {
		console.log(sizes, props.size);
		if (props.size) setAspect(sizes[props.size].aspect);
		const img = new Image();
		img.onload = () => {
			setWaiting(false) ;
			setImgWidth(img.width);
			setImgHeight(img.height);
			setReady(true);
		};
		img.onerror = (ev) => {
			console.log ('no picture!',ev) ;
			return ;
			setWaiting(true) ;
			if (!waitRef.current) waitRef.current = 0 ;
			console.log (waitRef.current) ;
			if (waitRef.current == 3) {
				setError(true) ;
				return ;
			}
			waitRef.current++ ;
			setTimeout(() => {
				img.src = getDiskFilename(props.hash, "512x512");
			},5000) ;
		}
		//setTimeout(function(){

			img.src = getDiskFilename(props.hash, "512x512");
		//},1000);
	}, []);


	const doCrop = async () => {
		setWorking(true);
		doAlg(1, []);
	};

	const doTryCrop = async () => {
		if (!props.try) return doCrop();
		setWorking(true);
		let r = [];

		let loaded = await jsonExec("test/go", { hash: props.hash, area, rotate: settings.rotate * 90, size_id: 7, palette_id: 6, aid: 1, iw: Math.max(imgWidth, imgHeight) });
		r.push({ hash: loaded.hash, title: '30х30 см '+palettes[6].title });
		loaded = await jsonExec("test/go", { hash: props.hash, area, rotate: settings.rotate * 90, size_id: 7, palette_id: 7, aid: 1, iw: Math.max(imgWidth, imgHeight) });
		r.push({ hash: loaded.hash, title: '30х30 см '+palettes[7].title });
		loaded = await jsonExec("test/go", { hash: props.hash, area, rotate: settings.rotate * 90, size_id: 5, palette_id: 6, aid: 1, iw: Math.max(imgWidth, imgHeight) });
		r.push({ hash: loaded.hash, title: '40х40 см '+palettes[6].title });
		loaded = await jsonExec("test/go", { hash: props.hash, area, rotate: settings.rotate * 90, size_id: 5, palette_id: 7, aid: 1, iw: Math.max(imgWidth, imgHeight) });
		r.push({ hash: loaded.hash, title: '40х40 см '+palettes[7].title });
		setExamples(r);
		setWorking(false);
	}

	const doAlg = async (aid, rrr) => {
		console.log("======================= " + aid);
		//const loaded = await jExec("test/go", { hash: props.hash, area, size_id: size, palette_id: palette, aid, iw: 512 });
		const loaded = await jsonExec("test/go", { hash: props.hash, area, rotate: settings.rotate * 90, set_id: props.set_id, aid, iw: Math.max(imgWidth, imgHeight) });
		//console.log (rrr, aid, loaded) ;
		let r = [...rrr];
		let done = false;
		let stop = false;

		for (let i in r) {
			if (loaded.aid && loaded.aid == r[i].aid && r[i].hash) {
				stop = true;
			}
			if (r[i].aid == aid) {
				r[i].hash = loaded.hash;
				done = true;
			}
		}
		if (!done) r.push({ aid, hash: loaded.hash });

		setWorking(r.length) ;
		setResults(r);
		if (loaded.aid && !stop) {
			doAlg(loaded.aid, r);
		} else {
			setWorking(false) ;
		}
	};

	const selectSize = (size_id) => {
		setSize(size_id);
		setAspect(sizes[size_id].aspect);
	};


	const changeAspect = () => {
		setAspect(1 / aspect);
	};

	const onSelect = (hash) => {
		if (props.onSelect) props.onSelect(hash);
	}

	const clearExamples = useCallback(() => {
		setExamples([]);
	})

	const clearResults = useCallback(() => {
		setResults([]);
	})

	if (error) {
		return <div className={css.working}>
			<div>Нам очень жаль, но при загрузке фотографии произошла ошибка.</div>
			<div>Пожалуйста сообщите об ошибке на электронную почту, указанную в наборе, и мы Вам обязательно поможем.</div>
		</div>
	}

	if (waiting) {
		return <div className={css.working}>
			<div>Загружаем вашу фотографию ...</div>
			<CircleLoader color="#ccc" size={128} />
		</div>
	}



	if (!ready) return null;

	if (working) {
		return <div className={css.working}>
			<div>Подготавливаем варианты мозаики ...</div>
			<CircleLoader color="#ccc" size={128} />
			{working !==true && <div>{getNumberName(working, ['Готов','Готово','Готово'])} {working} {getNumberName(working, ['вариант','варианта','вариантов'])}</div> }
		</div>
	}

	if (examples.length) {
		return <div className={css.examples}>
			<div className={css.text}>В полной версии Вам будет доступно 7 вариантов выкладки изображения.</div>
			<div className={css.list}>
				{examples.map((v, i) =>
					<div key={i}>
						<img src={getDiskFilename(v.hash, "512x")} />
						<div>{v.title}</div>
					</div>
				)}
			</div>
			<div className={css.options}>
				<button onClick={clearExamples} className={css.default}>Изменить выбранную область</button>
				<Link to="/try" className={css.default}>Попробовать другое изображение</Link>
			</div>
		</div>
	}

	if (results.length) {
		return <div className={css.results}>
			<div className={css.title}>Выберите понравившийся вариант</div>
			<div className={css.list}>
				{results.map((v, i) => typeof v.hash == 'string' && v.hash.length>0 &&
					<div key={i} onClick={() => onSelect(v.hash)}>
						<img src={getDiskFilename(v.hash, "512x")} />
					</div>
				)}
			</div>
			<div className={css.options}>
				<button onClick={clearResults} className={css.default}>Изменить выбранную область</button>
				<button className={css.default} onClick={props.onCancel}>Попробовать другое изображение</button>
			</div>
		</div>
	}

	return (
		<div className={css.main}>
			<div className={css.instruction}>Выделите желаемую зону на своём изображении</div>
			<ImageAreaSelector
				default={settings}
				style={{ marginBottom: 20 }}
				aspect={aspect}
				onChange={onChange}
				imageWidth={imgWidth}
				imageHeight={imgHeight}
				image={getDiskFilename(props.hash, "512x512")}
			/>
			<div className={css.instruction}>Перемещайте изображение, чтобы получить нужный результат.<br />Используйте колёсико мыши, чтобы изменить масштаб.</div>
			<div className={css.menu}>
				{props.onCancel ?
					<button className={css.default} onClick={props.onCancel}>Назад</button>
					:
					<Link to={props.try ? "/try" : "/create"} className={css.default}>Назад</Link>
				}
				<button className={css.success} onClick={doTryCrop}>Далее</button>
			</div>
		</div>
	);
}
